import React, { useEffect } from "react";
import { navigate } from "gatsby";

const NotFound = () => {
    useEffect(() => {
        navigate("/", { replace: true });
    }, []);

    return (
        <noscript>
            <p>
                {"You must have javascript enabled to use this application"}
            </p>
        </noscript>
    );
};

export default NotFound;